<template>
  <div>
      <div class="card" v-if="loading">
          <div class="card-body">
               <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                </div>
          </div>
      </div>
      <div class="card" v-else-if="error">
          <div class="card-body">
               <div class="text-center text-danger my-2">
                    <span class="mb-2 d-block">Erro ao carregar dados!</span>
                    <b-button
                        variant="info"
                        @click.prevent="$router.push({ name: 'produtos' })"
                        >
                        <i class="bx bx-list-ol font-size-16 align-middle mr-2"></i>
                        Listar Produtos
                    </b-button>
                </div>
          </div>
      </div>
      <Product v-else :edit="produto"/>
  </div>
</template>

<script>
import Product from './components/Product'
import { mapActions } from 'vuex'
export default {
  components: {
    Product,
  },
   created () {
      this.findProduto()
  },
  data() {
    return {
        produto: null,
        loading: true,
        error: false
    }
  },
    methods: {
       ...mapActions('product', ['ActionProductFind']),
       async findProduto(){
           try {
                await this.ActionProductFind(this.$route.params.id).then(res=>{                    
                    this.produto = res.data.data
                    this.loading = false
                    this.error = false
                })
           } catch (e) {
               this.loading = false
               this.error = true
               this.produto = null
           }
       }
  },
}
</script>
